<template>
  <b-form-group
    class="pdf-schema-form-address-group"
    :disabled="useHint && checkboxState"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" />
    <!-- Name ------------------------------------------------------------------------------------->
    <b-row
      v-if="displayField('name')"
      class="sortable-field-container"
      :tabindex="schemaTabIndex(fieldIds.name)"
    >
      <b-col xs="12" class="mobile-padding">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.name)"
          name="Name"
        >
          <b-form-input
            v-model="formData[fieldIds.name]"
            placeholder="Name"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('name')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.name)"
          />
          <p v-if="errors('Name')" class="invalid">
            {{ errors('Name') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <!-- Attention -------------------------------------------------------------------------------->
    <b-row
      v-if="displayField('attention')"
      class="sortable-field-container"
      :tabindex="schemaTabIndex(fieldIds.attention)"
    >
      <b-col xs="12">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.attention)"
          name="Attention"
        >
          <b-form-input
            v-model="formData[fieldIds.attention]"
            placeholder="Attention"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('attention')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.attention)"
          />
          <p v-if="errors('Attention')" class="invalid">
            {{ errors('Attention') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row
      v-if="displayField('line1') || displayField('line2')"
      class="sortable-field-container"
    >
      <!-- Line 1 --------------------------------------------------------------------------------->
      <b-col
        xs="12"
        class="mobile-padding"
        :tabindex="schemaTabIndex(fieldIds.line1)"
        :md="displayField('line2') ? 8 : 12"
      >
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.line1)"
          name="Line 1"
        >
          <b-form-input
            v-if="displayField('line1')"
            v-model="formData[fieldIds.line1]"
            placeholder="Line 1"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('line1')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.line1)"
          />
          <p v-if="errors('Line 1')" class="invalid">
            {{ errors('Line 1') }}
          </p>
        </validation-provider>
      </b-col>

      <!-- Line 2 --------------------------------------------------------------------------------->
      <b-col
        xs="12"
        class="mobile-padding"
        :tabindex="schemaTabIndex(fieldIds.line2)"
      >
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.line2)"
          name="Line 2"
        >
          <b-form-input
            v-if="displayField('line2')"
            v-model="formData[fieldIds.line2]"
            placeholder="Line 2"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('line2')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.line2)"
          />
          <p v-if="errors('Line 2')" class="invalid">
            {{ errors('Line 2') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row
      v-if="displayField('city') || displayField('state_province_region')"
      class="sortable-field-container"
    >
      <!-- City ----------------------------------------------------------------------------------->
      <b-col
        xs="12"
        class="mobile-padding"
        :tabindex="schemaTabIndex(fieldIds.city)"
        :md="displayField('state_province_region') ? 6 : 12"
      >
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.city)"
          name="City"
        >
          <b-form-input
            v-if="displayField('city')"
            v-model="formData[fieldIds.city]"
            placeholder="City"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('city')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.city)"
          />
          <p v-if="errors('City')" class="invalid">
            {{ errors('City') }}
          </p>
        </validation-provider>
      </b-col>

      <!-- State/Province/Region ------------------------------------------------------------------>
      <b-col
        xs="12"
        class="mobile-padding"
        :tabindex="schemaTabIndex(fieldIds.state_province_region)"
      >
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.state_province_region)"
          name="State/Province/Region"
        >
          <b-form-input
            v-if="displayField('state_province_region')"
            v-model="formData[fieldIds.state_province_region]"
            placeholder="State/Province/Region"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('state_province_region')"
            @update="update"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.state_province_region)"
          />
          <p v-if="errors('State/Province/Region')" class="invalid">
            {{ errors('State/Province/Region') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row
      v-if="displayField('country') || displayField('zip_postal_code')"
      class="sortable-field-container"
    >
      <!-- Country -------------------------------------------------------------------------------->
      <b-col
        xs="12"
        :class="displayField('country') ? 'mobile-padding' : ''"
        :tabindex="schemaTabIndex(fieldIds.country)"
        :md="displayField('country') ? 8 : 12"
      >
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.country)"
          name="Country"
        >
          <b-form-select
            v-if="displayField('country')"
            v-model="formData[fieldIds.country]"
            placeholder="Country"
            style="cursor: pointer;"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('country')"
            :options="countries()"
            @update="update"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.country)"
          />
          <p v-if="errors('Country')" class="invalid">
            {{ errors('Country') }}
          </p>
        </validation-provider>
      </b-col>

      <!-- ZIP/Postal Code ------------------------------------------------------------------------>
      <b-col
        xs="12"
        class="mobile-padding"
        :tabindex="schemaTabIndex(fieldIds.zip_postal_code)"
      >
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.zip_postal_code)"
          name="ZIP/Postal Code"
        >
          <b-form-input
            v-if="displayField('zip_postal_code')"
            v-model="formData[fieldIds.zip_postal_code]"
            v-mask="formData[fieldIds.zip_postal_code]?.length > 5 ? '#####-####' : '#####'"
            placeholder="Zip/Postal Code"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('zip_postal_code')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.zip_postal_code)"
          />
          <p v-if="errors('ZIP/Postal Code')" class="invalid">
            {{ errors('ZIP/Postal Code') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <!-- County ----------------------------------------------------------------------------------->
    <b-row
      v-if="displayField('county')"
      class="sortable-field-container"
      :tabindex="schemaTabIndex(fieldIds.county)"
    >
      <b-col xs="12">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.county)"
          name="County"
        >
          <b-form-input
            v-model="formData[fieldIds.county]"
            placeholder="County"
            style="cursor: pointer;"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('county')"
            @update="update"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.county)"
          />
          <p v-if="errors('County')" class="invalid">
            {{ errors('County') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <!-- Address (Entire Address, not broken down by components)----------------------------------->
    <b-row
      v-if="displayField('address')"
      class="sortable-field-container"
      :tabindex="schemaTabIndex(fieldIds.address)"
    >
      <b-col xs="12">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.address)"
          name="Address"
        >
          <b-form-input
            v-model="formData[fieldIds.address]"
            placeholder="Address"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('address')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.address)"
          />
          <p v-if="errors('Address')" class="invalid">
            {{ errors('Address') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>
  </b-form-group>
</template>

<script>
import { countries } from '@/common/modules/countries'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PdfSchemaFormAddressGroup',
  components: {
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    useHint: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      backupAddress: {},
    }
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'formData',
      'mapFieldIdsToGroupedFieldsParts',
      'resourceFields',
      'isDisabledField',
      'validationRulesForFieldId',
      'validationState',
      'pdfAddressCheckboxState',
      'schemaTabIndex',
      'pdfFieldsOptional',
    ]),
    initialField() {
      return this.fields[0]
    },
    title() {
      return this.initialField.title || this.initialField.original_field_label
    },
    fieldIds() {
      return this.mapFieldIdsToGroupedFieldsParts(this.fields)
    },
    checkboxState() {
      return this.pdfAddressCheckboxState[this.fields[0].id]
    },
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'setCurrentFieldById',
    ]),
    isDisabledFieldComponent(fieldName) {
      return this.isDisabledField(this.fields.find(f => f.id === this.fieldIds[fieldName]))
    },
    update(value) {
      if ([null, false, '', 'USA', 'United States'].includes(value?.country) && !this.pdfFieldsOptional) {
        value.country = "US"
      }
      this.$emit('input', value)
    },
    countries: () => countries,
    displayField(fieldName) {
      return Object.keys(this.fieldIds).includes(fieldName)
    },
    errors(fieldName) {
      return this.$refs.observer?.$_veeObserver?.errors && this.$refs.observer.$_veeObserver.errors[fieldName] ?
        this.$refs.observer.$_veeObserver.errors[fieldName].filter(error => !error.includes('required'))[0] :
        null
    },
    handleEnterPressed(e) {
      this.$emit('enterPressed', e.shiftKey ? 'prev' : 'next')
    },
  },
}
</script>

<style scoped lang="scss">
.schema-form__validation-message {
  color: red;
}
.pdf-schema-form-address-group {
  margin-bottom: 0 !important;
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}
.invalid {
  font-size: 80%;
  color: #dc3545;
  margin-bottom: 0;
}

@media only screen and (max-width: 660px) {
  .sortable-field-container {
    width: 100% !important;
    margin: 0 !important;
  }

  .mobile-padding {
    padding: 0.8em 0 !important;
  }
}
</style>
